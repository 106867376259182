import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { MerchantStore } from 'src/app/core/domain/merchant-store.model';
import { AddToCartUseCase } from 'src/app/core/usecases/cart/add-to-cart.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { AddProductToStoreUseCase } from 'src/app/core/usecases/merchant-store/add-product-to-store.usecase';
import { GetUserStoresUseCase } from 'src/app/core/usecases/merchant-store/get-user-stores-usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { CatalogService } from 'src/app/presentation/shared/services/catalog.service';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import {
  getSizedImage,
  ImageSize,
} from 'src/app/presentation/shared/utilities/get-sized-image.utility';
import { ProductCardSideEffects } from './product-card.side-effects';
import { ProductCardViewEvents } from './product-card.view-events';
import { ProductCardViewState } from './product-card.view-state';

@Injectable()
export class ProductCardPresenter extends BasePresenter<
  ProductCardViewState,
  ProductCardViewEvents,
  ProductCardSideEffects
> {
  private _languageChangeSubscription: Subscription;

  constructor(
    private _siteTranslationService: SiteTranslateService,
    private _multitenancyService: MultitenancyService,
    private _catalogService: CatalogService,
    private _addToCartUseCase: AddToCartUseCase,
    private _localStorageService: LocalStorageService,
    private _getUserStoresUseCase: GetUserStoresUseCase,
    private _addProductToStoreUseCase: AddProductToStoreUseCase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  protected defaultViewState(): ProductCardViewState {
    return {
      textDirection: null,
      currency: null,
      currentCountry: null,
      productIsInCatalog: false,
      productId: null,
      productIsNotOrderable: false,
      productImg: '',
      userHasStore: false,
      storeId: '',
      storeProvider: '',
      productAddedToStore: false,
      productIsOnSale: false,
      flashSaleTitle: '',
      saleCountDownTimer: null,
      sku: '',
    };
  }

  protected onViewEvent(event: ProductCardViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          productId: event.productId,
          currency: this._multitenancyService.getCurrentCountry().currency,
          currentCountry: this._multitenancyService.getCurrentCountry(),
          productIsInCatalog: this._catalogService.isProductCataloged(event.productId),
          productImg: this.resizeImages(event.productImg),
          productIsNotOrderable: this.getProductIsOrderable(event.isPreOrderable),
          productIsOnSale: event.productIsOnSale,
          sku: event.sku,
        });
        this.setUpTextDirection();
        this._fetchUserStores();
        if (event.saleExpiresAt) this.setupProductOnSale(event.saleExpiresAt);
        break;
      }
      case 'AddToCart': {
        this.addProductToCart();
        break;
      }
      case 'AddToCatalog': {
        this.addProductToCatalog();
        break;
      }
      case 'RemoveFromCatalog': {
        this.removeProductFromCatalog();
        break;
      }
      case 'AddToStore': {
        this.addProductToStore();
        break;
      }
    }
  }

  private setUpTextDirection(): void {
    this._siteTranslationService.onTextDirectionChange().subscribe((dir) => {
      this.updateViewState({
        ...this.viewState,
        textDirection: dir,
      });
    });
  }

  private _fetchUserStores(): void {
    this._getUserStoresUseCase.execute().subscribe({
      next: (stores) => {
        const selectedCountry = this.viewState.currentCountry?.isoCode3;
        const storePerMarket = stores.filter((store) => store.market === selectedCountry);
        if (storePerMarket.length > 0) {
          const { storeId, provider } = storePerMarket[0];
          this.updateViewState({
            ...this.viewState,
            userHasStore: true,
            storeId,
            storeProvider: provider,
          });
        }
      },
    });
  }

  private setupProductOnSale(saleExpiresAt: string): void {
    this.getFlashSaleTitle();
    this._setupSaleExpiryTime(saleExpiresAt);
  }

  public getFlashSaleTitle(): void {
    if (this.viewState.productIsOnSale && !this.viewState.flashSaleTitle.length) {
      this._getFeatureAttributeUsecase.execute(FEATURE_FLAGS.FLASH_SALE_TITLE).subscribe({
        next: (title) => {
          this.updateViewState({
            ...this.viewState,
            flashSaleTitle: title,
          });
        },
      });
    }
  }

  private _setupSaleExpiryTime(saleExpiresAt: string): void {
    const expiryTime = saleExpiresAt;
    if (expiryTime) {
      let countDownTimer = new Date(expiryTime).valueOf() - new Date().valueOf();
      const timerInterval = setInterval(() => {
        const countDownTimerValue = new Date(expiryTime).valueOf() - new Date().valueOf();
        countDownTimer = countDownTimerValue > 0 ? countDownTimerValue : 0;
        this.updateViewState({
          ...this.viewState,
          saleCountDownTimer: countDownTimer,
        });
        if (countDownTimer === 0) {
          this.updateViewState({
            ...this.viewState,
            productIsOnSale: false,
          });
          clearInterval(timerInterval);
        }
      }, 1000);
    }
  }

  private getProductIsOrderable(isPreOrderable: boolean): boolean {
    const userPreorderRequests = this._localStorageService.getStorage('preorderRequests');
    if (userPreorderRequests && isPreOrderable) {
      const currentProductInProgressPreorderRequests: any = userPreorderRequests.filter(
        (request: any) =>
          request.prodId === this.viewState.productId &&
          request.status === PreOrderStatuses.INPROGRESS,
      );
      return currentProductInProgressPreorderRequests.length === 0;
    }
    return false;
  }

  private resizeImages(img: string): string {
    return getSizedImage(img, ImageSize.medium);
  }

  private addProductToCart(): void {
    this._addToCartUseCase
      .execute({ productId: this.viewState.productId!, quantity: 1 })
      .subscribe({
        next: () => {
          this.emitSideEffect({
            type: 'ShowSuccessToaster',
            messageKey: 'PRODUCTS_PAGE.PRODUCT_CARD.ADDED_TO_CART',
          });
          this.emitSideEffect({
            type: 'TrackEvent',
            eventType: 'add_to_cart',
          });
        },
        error: () => {},
      });
  }

  private addProductToCatalog(): void {
    this._catalogService.catalogProduct(this.viewState.productId!).subscribe({
      next: () => {
        this.updateViewState({
          ...this.viewState,
          productIsInCatalog: true,
        });
        this.emitSideEffect({
          type: 'ShowSuccessToaster',
          messageKey: 'PRODUCTS_PAGE.PRODUCT_ADDED_TO_CATALOG',
        });
      },
      error: () => {
        this.emitSideEffect({
          type: 'ShowErrorToaster',
          messageKey: 'ERRORS.GENERIC_ERROR_MESSAGE',
        });
      },
    });
  }

  private removeProductFromCatalog(): void {
    this._catalogService.uncatalogProduct(this.viewState.productId!).subscribe({
      next: () => {
        this.updateViewState({
          ...this.viewState,
          productIsInCatalog: false,
        });
        this.emitSideEffect({
          type: 'ShowSuccessToaster',
          messageKey: 'CATALOG_PAGE.SUCCESS.PRODUCT_REMOVED',
        });
        this.emitSideEffect({ type: 'TrackEvent', eventType: 'remove_from_catalog' });
      },
      error: () => {
        this.emitSideEffect({
          type: 'ShowErrorToaster',
          messageKey: 'ERRORS.GENERIC_ERROR_MESSAGE',
        });
      },
    });
  }

  private addProductToStore(): void {
    const merchantStore: MerchantStore = {
      storeId: this.viewState.storeId,
      provider: this.viewState.storeProvider,
    };
    this.emitSideEffect({ type: 'TrackEvent', eventType: 'add_to_store_clicked' });
    this._addProductToStoreUseCase
      .execute({ merchantStore, productId: this.viewState.sku! })
      .subscribe({
        next: () => {
          this.updateViewState({
            ...this.viewState,
            productAddedToStore: true,
          });
          this.emitSideEffect({
            type: 'ShowSuccessToaster',
            messageKey: 'STORES.ADDED_SUCCESSFULLY',
          });
          this.emitSideEffect({ type: 'TrackEvent', eventType: 'add_to_store_success' });
        },
        error: (err) => {
          this.emitSideEffect({ type: 'ShowErrorToaster', messageKey: err.error.description });
          this.emitSideEffect({ type: 'TrackEvent', eventType: 'add_to_store_fail' });
        },
      });
  }
}
