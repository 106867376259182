import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { CommercialCategoryTreeNode } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';

@Component({
  selector: 'app-categories-sidebar',
  templateUrl: './categories-sidebar.component.html',
  styleUrls: ['./categories-sidebar.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, RouterLink, NgIf, TranslateModule],
})
export class CategoriesSidebarComponent implements OnInit, OnDestroy {
  @Input() selectedCategoryName: string;

  @Input() selectedCategoryHierarchy: CommercialCategoryTreeNode[];

  @Input() isPreOrderable: Boolean;

  @Input() isNewPreOrderEnabled: Boolean;

  @Output() emittedCategoryId = new EventEmitter<{ id: string }>();

  selectedCategoryNodes: {
    levelOne: CommercialCategoryTreeNode;
    levelTwo: CommercialCategoryTreeNode;
    levelThree: CommercialCategoryTreeNode;
  };

  levelTwoCategoryNodes: CommercialCategoryTreeNode[];

  public isEnglishLanguageSelected: boolean;

  private _languageChangeSubscription: Subscription;

  constructor(private _translateService: TranslateService, private _router: Router) {}

  ngOnInit(): void {
    this.getSelectedCategoryNodes(this.selectedCategoryHierarchy);
    this.isEnglishLanguageSelected = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguageSelected = lang === ENGLISH_LANGUAGE;
      },
    });
  }

  getSelectedCategoryNodes(categoryHierachy: CommercialCategoryTreeNode[]): void {
    this.selectedCategoryNodes = {
      levelOne: categoryHierachy[categoryHierachy.length - 2],
      levelTwo: categoryHierachy[categoryHierachy.length - 3],
      levelThree: categoryHierachy[categoryHierachy.length - 4],
    };
    this.levelTwoCategoryNodes = this.selectedCategoryNodes.levelOne.children;
  }

  onCategoryClicked(id: string): void {
    if (this.isNewPreOrderEnabled) {
      this.emittedCategoryId.emit({ id });
    } else {
      this._router.navigate(['/', 'products', 'category', id], {
        queryParams: {
          preorderablProducts: this.isPreOrderable,
        },
      });
    }
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
