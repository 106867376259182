import { inject, Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetPreOrderRequestsSummaryUseCase } from 'src/app/core/usecases/bulk-preorder/get-pre-order-requests-summary.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { LocalizedPreOrderStatusesMapper } from 'src/app/presentation/pre-order-requests/pre-order-request-statuses';
import { PreOrderProgressRequestsSummarySideEffect } from './preorder-progress-requests-summary.sideEffects';
import { PreOrderProgressRequestsSummaryViewEvents } from './preorder-progress-requests-summary.viewEvents';
import { PreOrderProgressRequestsSummaryViewState } from './preorder-progress-requests-summary.viewState';

@Injectable()
export class PreOrderProgressRequestsSummaryPresenter extends BasePresenter<
  PreOrderProgressRequestsSummaryViewState,
  PreOrderProgressRequestsSummaryViewEvents,
  PreOrderProgressRequestsSummarySideEffect
> {
  private _getPreOrderRequestsSummaryUseCase = inject(GetPreOrderRequestsSummaryUseCase);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): PreOrderProgressRequestsSummaryViewState {
    return {
      preOrderRequestSummary: [],
    };
  }

  protected onViewEvent(event: PreOrderProgressRequestsSummaryViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._getPreOrderRequestsSummaryUseCase.execute().subscribe((data) => {
          this.updateViewState({
            preOrderRequestSummary: data.preorderStatusSummary.map((summary) => ({
              status: LocalizedPreOrderStatusesMapper(summary.status),
              numberOfRequests: summary.numberOfRequests,
            })),
          });
        });
        break;
      }
      case 'NavigateToPreOrderRequestsPageClicked': {
        this._logMixpanelEventUseCase.execute({
          eventName: 'new_pre_order_progress_card_button_clicked',
        });
        this.emitSideEffect({
          type: 'NavigateToPreOrderRequestsPage',
        });
        break;
      }
    }
  }
}
