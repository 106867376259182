<div class="categories-sidebar-container">
  <div class="categories-sidebar">
    <p class="sidebar-title">{{ 'CATEGORY_PAGE.SUB_CATEGORIES' | translate }}</p>
    <div class="categories-levels-two" *ngFor="let levelTwoCategoryNode of levelTwoCategoryNodes">
      <div
        class="level-container"
        [ngClass]="{
          'level-container--selected':
            levelTwoCategoryNode.key === selectedCategoryNodes.levelTwo?.key
        }"
      >
        <i
          class="icon custom-chevron"
          [ngClass]="isEnglishLanguageSelected ? 'icon-right-chevron' : 'icon-left-chevron'"
          aria-hidden="true"
        ></i>
        <a class="level-button" (click)="onCategoryClicked(levelTwoCategoryNode.key)">
          {{
            isEnglishLanguageSelected
              ? levelTwoCategoryNode.value.name.englishName
              : levelTwoCategoryNode.value.name.arabicName
          }}
        </a>
      </div>
      <div
        class="level-three-container"
        *ngIf="levelTwoCategoryNode.key === selectedCategoryNodes.levelTwo?.key"
      >
        <div *ngFor="let levelThreeCategoryNode of levelTwoCategoryNode.children">
          <a
            class="level-three-text"
            (click)="onCategoryClicked(levelThreeCategoryNode.key)"
            [ngClass]="{
              'level-three-text--selected':
                selectedCategoryNodes.levelThree?.key === levelThreeCategoryNode.key
            }"
          >
            {{
              isEnglishLanguageSelected
                ? levelThreeCategoryNode.value.name.englishName
                : levelThreeCategoryNode.value.name.arabicName
            }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
