import { Pipe, PipeTransform } from '@angular/core';
import { ProductAvailability } from 'src/app/core/domain/variant-group.model';

@Pipe({
  name: 'productAvailability',
  standalone: true,
})
export class ProductAvailabilityPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case ProductAvailability.not_available:
        return 'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_NOT_AVAILABLE';
      case ProductAvailability.available_with_low_qty:
        return 'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_WITH_LOW_QUANTITY';
      case ProductAvailability.available_with_high_qty:
        return 'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_WITH_HIGH_QUANTITY';
      case ProductAvailability.available:
        return 'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE';
      case ProductAvailability.available_for_preorder:
        return 'PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_AVAILABLE_FOR_PRE_ORDER';
      default:
        return value;
    }
  }
}
