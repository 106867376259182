import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-preorder-empty-requests-summary',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './preorder-empty-requests-summary.component.html',
  styleUrls: ['./preorder-empty-requests-summary.component.scss'],
})
export class PreorderEmptyRequestsSummaryComponent {
  public assetsPath = 'assets/img/pre-order-banner/';
}
