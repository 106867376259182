import { inject, Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { GetPreOrderBenefitsUseCase } from 'src/app/core/usecases/bulk-preorder/get-pre-order-benefits.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { mixPanelEvent } from '../../../../../../v3/features/activities/data';
import { preOrderTrackEvents } from '../../../../../../v3/features/pre-order/data';
import { PreOrderTopBannerSideEffect } from './preorder-top-banner.sideEffects';
import { PreOrderTopBannerViewEvents } from './preorder-top-banner.viewEvents';
import { PreOrderTopBannerViewState } from './preorder-top-banner.viewState';

@Injectable()
export class PreOrderTopBannerPresenter extends BasePresenter<
  PreOrderTopBannerViewState,
  PreOrderTopBannerViewEvents,
  PreOrderTopBannerSideEffect
> {
  private _getPreOrderBenefitsUseCase = inject(GetPreOrderBenefitsUseCase);

  protected defaultViewState(): PreOrderTopBannerViewState {
    return {
      preOrderBenefitsData: [],
      isKSASelected: false,
    };
  }

  protected onViewEvent(event: PreOrderTopBannerViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._getPreOrderBenefitsUseCase.execute().subscribe((data) => {
          this.updateViewState({
            preOrderBenefitsData: data,
            isKSASelected: country.is('KSA'),
          });
        });
        break;
      }
      case 'ClickScrollToProducts': {
        mixPanelEvent(preOrderTrackEvents.clickScrollToPreOrderProducts);
        this.emitSideEffect({
          type: 'ScrollToProducts',
        });
        break;
      }

      case 'ClickScrollToExternalProducts': {
        mixPanelEvent(preOrderTrackEvents.clickScrollToPreOrderExternalProducts);
        this.emitSideEffect({
          type: 'ScrollToExternalProducts',
        });
        break;
      }
    }
  }
}
