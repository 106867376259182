import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { finalize, map } from 'rxjs/operators';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { SharedService } from 'src/app/presentation/shared/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  catalogedProductsIds: string[] = [];

  constructor(private http: HttpClient, private sharedService: SharedService) {}

  catalogProduct(id: string): Observable<any> {
    return this.http.patch(API_URLS.CATALOG_PRODUCT_BY_ID_URL(id), null).pipe(
      finalize(() => {
        this.emitCatalogChange();
      }),
      map((data) => {
        const index = this.catalogedProductsIds.indexOf(id);
        if (index === -1) {
          this.catalogedProductsIds.push(id);
        }
        return data;
      }),
    );
  }

  uncatalogProduct(id: string): Observable<any> {
    return this.http.patch(API_URLS.UNCATALOG_PRODUCT_BY_ID_URL(id), null).pipe(
      finalize(() => {
        this.emitCatalogChange();
      }),
      map((data) => {
        const index = this.catalogedProductsIds.indexOf(id);
        if (index > -1) {
          this.catalogedProductsIds.splice(index, 1);
        }
        return data;
      }),
    );
  }

  /**
   * @description This function is used to check if a product is cataloged by using an API call
   */
  getProductIsCataloged(id: string): Observable<any> {
    return this.http.get(API_URLS.GET_PRODUCT_IS_CATALOGED(id));
  }

  /**
   * @description This function checks if a product exists in catalogedProductsIds array
   */
  isProductCataloged(id: string): boolean {
    return this.catalogedProductsIds.indexOf(id) !== -1;
  }

  getCatalogedProducts(isLocked?: boolean): Observable<any> {
    if (!isLocked) {
      isLocked = false;
    }
    return this.http.get(API_URLS.GET_CATALOGED_PRODUCTS(isLocked)).pipe(
      map((data: any[]) => {
        this.catalogedProductsIds = data.map((product) => product._id);
        return data;
      }),
    );
  }

  updateCustomPrice(id: string, newPrice: string) {
    return this.http.patch(API_URLS.UPDATE_CUSTOM_PRICE(id), { price: newPrice });
  }

  emitCatalogChange(): void {
    this.sharedService.emitshoppingItemCountChanged();
  }
}
