import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { PreorderEmptyRequestsSummaryComponent } from './preorder-empty-requests-summary/preorder-empty-requests-summary.component';
import { PreorderProgressRequestsSummaryComponent } from './preorder-progress-requests-summary/preorder-progress-requests-summary.component';
import { PreOrderRequestsSummaryPresenter } from './presenter/preorder-requests-summary.presenter';
import { PreOrderRequestsSummarySideEffect } from './presenter/preorder-requests-summary.sideEffects';
import { PreOrderRequestsSummaryViewEvents } from './presenter/preorder-requests-summary.viewEvents';
import { PreOrderRequestsSummaryViewState } from './presenter/preorder-requests-summary.viewState';

@Component({
  selector: 'app-preorder-requests-summary',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    PreorderProgressRequestsSummaryComponent,
    PreorderEmptyRequestsSummaryComponent,
  ],
  templateUrl: './preorder-requests-summary.component.html',
  styleUrls: ['./preorder-requests-summary.component.scss'],
  providers: [PreOrderRequestsSummaryPresenter],
})
export class PreorderRequestsSummaryComponent extends BaseComponent<
  PreOrderRequestsSummaryPresenter,
  PreOrderRequestsSummaryViewState,
  PreOrderRequestsSummaryViewEvents,
  PreOrderRequestsSummarySideEffect
> {
  public assetsPath = 'assets/img/pre-order-banner/';

  public presenter: PreOrderRequestsSummaryPresenter = inject(PreOrderRequestsSummaryPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
  }
}
