<div class="preorder-banner">
  <div class="preorder-banner__intro" [ngClass]="{ 'bottom-space': viewState.isKSASelected }">
    <p class="heading1--bold white-text">{{ trans('PRE_ORDER_PAGE.BANNER.ADS') }}</p>
    <p class="heading1--bold white-text">{{ trans('PRE_ORDER_PAGE.BANNER.SERVICE') }}</p>
    <p class="body1--regular content-medium-color banner-mini-text">
      {{ trans('PRE_ORDER_PAGE.BANNER.RESERVE') }}
    </p>
    <div class="banner-button__container" [ngClass]="{ 'reverse-row': isLTR }">
      <button
        class="scroll-button"
        (click)="presenter.emitViewEvent({
      type: 'ClickScrollToProducts',
    })"
      >
        {{ trans('PRE_ORDER_PAGE.BANNER.BROWSE') }}
        <img src="{{ assetsPath + 'white-arrow.svg' }}" />
      </button>
      <button
        class="request-button"
        (click)="presenter.emitViewEvent({
    type: 'ClickScrollToExternalProducts',
  })"
      >
        {{ trans('PRE_ORDER_PAGE.BANNER.SPECIAL_REQUEST') }}
      </button>
    </div>

    <div class="preorder-banner__card" [ngClass]="{ 'left-position': isLTR }">
      <div class="preorder-banner__card__content">
        <p [ngClass]="screenWidth > 1600 ? 'heading2--bold' : 'body1--bold'">
          {{ trans('PRE_ORDER_PAGE.BANNER.CARD4_TITLE_P1') }}
          <span class="highlight"> {{ trans('PRE_ORDER_PAGE.BANNER.CARD4_TITLE_P2') }}</span>
          {{ trans('PRE_ORDER_PAGE.BANNER.CARD4_TITLE_P3') }}
        </p>
        <img
          class="preorder-banner__card__content_arrow"
          src="assets/img/pre-order/back-arrow.svg"
          alt="arrow pointing towards external product request button"
        />
      </div>
    </div>
  </div>

  <div class="preorder-banner__center" *ngIf="viewState.isKSASelected">
    <img class="preorder-banner__video__icon" src="{{ assetsPath + 'gold-arrow.svg' }}" />
    <div class="preorder-banner__video">
      <iframe class="prepaid-video" src="https://www.youtube.com/embed/IsFBdhd1qTY"> </iframe>
    </div>
    <img class="preorder-banner__video__icon" src="{{ assetsPath + 'blue-arrow.svg' }}" />
  </div>
  <div class="preorder-banner__benefits">
    <p class="heading1--bold white-text">{{ trans('PRE_ORDER_PAGE.BANNER.UNLIMITED') }}</p>
    <p class="body1--regular white-text">{{ trans('PRE_ORDER_PAGE.BANNER.RESERVE_NOW') }}</p>
    <div class="benefits-card-container">
      <div class="benefits-card" *ngFor="let card of viewState.preOrderBenefitsData">
        <div class="benefits-card__header">
          <img class="benefits-card__header__icon" src="{{ card.icon }}" />
          <div>
            <p class="body1--bold white-text">{{ trans(card.title) }}</p>
            <p class="caption1--regular content-medium-color">{{ trans(card.subTitle) }}</p>
          </div>
        </div>
        <img class="benefits-card__img" src="{{ card.image }}" />
        <p class="body1--bold white-color white-text">{{ trans(card.header) }}</p>
        <p class="caption1--regular white-color content-medium-color">
          {{ trans(card.description) }}
        </p>
      </div>
    </div>
  </div>
</div>
