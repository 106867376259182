<div class="empty-summary">
  <img class="preorder-banner__video__icon" src="{{ assetsPath + 'empty-box.svg' }}" />
  <div>
    <p class="heading2--bold content-main-color">
      {{ 'PRE_ORDER_PAGE.SUMMARY.EMPTY.NO_ORDERS' | translate }}
    </p>
    <p class="body1--regular content-medium-color">
      {{ 'PRE_ORDER_PAGE.SUMMARY.EMPTY.FOLLOW' | translate }}
    </p>
  </div>
</div>
