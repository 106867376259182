import { inject, Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { GetPreOrderRequestsSummaryUseCase } from 'src/app/core/usecases/bulk-preorder/get-pre-order-requests-summary.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { PreOrderRequestsSummarySideEffect } from './preorder-requests-summary.sideEffects';
import { PreOrderRequestsSummaryViewEvents } from './preorder-requests-summary.viewEvents';
import { PreOrderRequestsSummaryViewState } from './preorder-requests-summary.viewState';

@Injectable()
export class PreOrderRequestsSummaryPresenter extends BasePresenter<
  PreOrderRequestsSummaryViewState,
  PreOrderRequestsSummaryViewEvents,
  PreOrderRequestsSummarySideEffect
> {
  private _getPreOrderRequestsSummaryUseCase = inject(GetPreOrderRequestsSummaryUseCase);

  protected defaultViewState(): PreOrderRequestsSummaryViewState {
    return {
      userName: '',
      isPreOrderSummaryEmpty: false,
      preOrderRequestSummary: [],
    };
  }

  protected onViewEvent(event: PreOrderRequestsSummaryViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._getPreOrderRequestsSummaryUseCase.execute().subscribe((data) => {
          this.updateViewState({
            isPreOrderSummaryEmpty: data.preorderStatusSummary.length === 0,
            userName: user.fullName,
            preOrderRequestSummary: data.preorderStatusSummary,
          });
        });
        break;
      }
    }
  }
}
