<div class="user-info">
  <div class="user-info__header">
    <img class="preorder-banner__video__icon" src="{{ assetsPath + 'person.svg' }}" />
    <p class="body1--bold content-main-color">
      {{ 'PRE_ORDER_PAGE.SUMMARY.WELCOME' | translate }} {{ viewState.userName }}
    </p>
  </div>
  <p class="heading1--bold content-main-color">
    {{ 'PRE_ORDER_PAGE.SUMMARY.KEEP_PROGRESS' | translate }}
  </p>
</div>
<div class="requests-summary">
  <app-preorder-empty-requests-summary
    *ngIf="viewState.isPreOrderSummaryEmpty; else notEmpty"
  ></app-preorder-empty-requests-summary>
  <ng-template #notEmpty>
    <app-preorder-progress-requests-summary
      class="progress-container"
    ></app-preorder-progress-requests-summary>
  </ng-template>
</div>
