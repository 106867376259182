import { NgClass, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { navigateTo } from '@presentation/shared/router';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { LocalizedPreOrderStatuses } from 'src/app/presentation/pre-order-requests/pre-order-request-statuses';
import { PreOrderProgressRequestsSummaryPresenter } from './presenter/preorder-progress-requests-summary.presenter';
import { PreOrderProgressRequestsSummarySideEffect } from './presenter/preorder-progress-requests-summary.sideEffects';
import { PreOrderProgressRequestsSummaryViewEvents } from './presenter/preorder-progress-requests-summary.viewEvents';
import { PreOrderProgressRequestsSummaryViewState } from './presenter/preorder-progress-requests-summary.viewState';

@Component({
  selector: 'app-preorder-progress-requests-summary',
  standalone: true,
  imports: [TranslateModule, NgFor, NgClass],
  templateUrl: './preorder-progress-requests-summary.component.html',
  styleUrls: ['./preorder-progress-requests-summary.component.scss'],
  providers: [PreOrderProgressRequestsSummaryPresenter],
})
export class PreorderProgressRequestsSummaryComponent extends BaseComponent<
  PreOrderProgressRequestsSummaryPresenter,
  PreOrderProgressRequestsSummaryViewState,
  PreOrderProgressRequestsSummaryViewEvents,
  PreOrderProgressRequestsSummarySideEffect
> {
  public assetsPath = 'assets/img/pre-order-banner/';

  public presenter: PreOrderProgressRequestsSummaryPresenter = inject(
    PreOrderProgressRequestsSummaryPresenter,
  );

  private _appURLs: { [url: string]: string } = inject(appUrlsConstantsInjectionToken);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  getCardStatusClass(status: LocalizedPreOrderStatuses): object {
    return {
      'started-status': status === LocalizedPreOrderStatuses.STARTED,
      'complete-status': status === LocalizedPreOrderStatuses.COMPLETED,
      'awaiting-special-products-status': [
        LocalizedPreOrderStatuses.AWAITING_EXTERNAL_PRODUCT_APPROVAL,
      ].includes(status),
      'awaiting-status': [LocalizedPreOrderStatuses.AWAITING_APPROVAL].includes(status),
      'money-transfer-approved-status':
        status === LocalizedPreOrderStatuses.MONEY_TRANSFERRED_APPROVED,
    };
  }

  onSideEffect(sideEffect: PreOrderProgressRequestsSummarySideEffect): void {
    switch (sideEffect.type) {
      case 'NavigateToPreOrderRequestsPage': {
        navigateTo(this._appURLs.PRE_ORDER_REQUESTS_URL);
        break;
      }
    }
  }
}
