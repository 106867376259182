<div class="categories-sidebar" *ngIf="categoriesNodes">
  <div class="sidebar-title">{{ 'CATEGORY_PAGE.CATEGORIES' | translate }}</div>
  <a
    class="category d-flex align-items-center"
    *ngFor="let categoryNode of categoriesNodes"
    [ngClass]="{
      'selected-category': categoryNode.value.name.arabicName === selectedCategory.arabicName
    }"
    (click)="onCategoryClicked(categoryNode.key)"
  >
    <img
      loading="lazy"
      class="category_icon"
      src="{{ iconsBaseUrl }}commercial-categories/icons/{{ categoryNode.value.icon }}"
      alt=""
    />
    <span class="category-name">{{
      isEnglishLanguageSelected
        ? categoryNode.value.name.englishName
        : categoryNode.value.name.arabicName
    }}</span>
  </a>
</div>
