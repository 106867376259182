<div class="progress-summary">
  <div class="progress-summary__header">
    <div>
      <p class="heading2--bold content-main-color">
        {{ 'PRE_ORDER_PAGE.SUMMARY.PROGRESS.ORDERS' | translate }}
      </p>
      <p class="body1--regular content-medium-color">
        {{ 'PRE_ORDER_PAGE.SUMMARY.PROGRESS.SUMMARY' | translate }}
      </p>
    </div>
    <button
      class="body1--bold progress-summary__header__button"
      (click)="presenter.emitViewEvent({
        type: 'NavigateToPreOrderRequestsPageClicked',
      })"
    >
      <img class="preorder-banner__video__icon" src="{{ assetsPath + 'small-box.svg' }}" />
      {{ 'PRE_ORDER_PAGE.SUMMARY.PROGRESS.DETAILS' | translate }}
    </button>
  </div>
  <div class="progress-summary__progress">
    <div *ngFor="let card of viewState.preOrderRequestSummary">
      <div class="progress-summary__progress__card" [ngClass]="getCardStatusClass(card.status)">
        <p class="caption2--regular">{{ card.status | translate }}</p>
        <p class="caption2--regular">{{ card.numberOfRequests }}</p>
      </div>
    </div>
  </div>
</div>
