import { NgFor } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { CommercialCategoryTreeNode } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';

@Component({
  selector: 'app-subcategory-filter',
  templateUrl: './subcategory-filter.component.html',
  styleUrls: ['./subcategory-filter.component.scss'],
  standalone: true,
  imports: [MatChipsModule, NgFor, RouterLink],
})
export class SubcategoryFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fourthLevelCategoriesList: CommercialCategoryTreeNode[];

  public isEnglishLanguageSelected: boolean;

  private _languageChangeSubscription: Subscription;

  public allCategoriesName = {
    arabicName: 'الكل',
    englishName: 'All',
  };

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    this.isEnglishLanguageSelected = this._translateService.currentLang === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: ({ lang }: { lang: string }) => {
        this.isEnglishLanguageSelected = lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const allFourthLevelCategoriesNode: CommercialCategoryTreeNode = {
      key: this.fourthLevelCategoriesList[0].parent!.key,
      value: {
        name: this.allCategoriesName,
        featured: false,
        icon: '',
      },
      parent: this.fourthLevelCategoriesList[0].parent,
      children: [],
      isLeaf: true,
      hasChildren: false,
    };
    const fourthLevelCategoriesListName = this.fourthLevelCategoriesList.map(
      (category) => category.value.name,
    );
    if (
      !fourthLevelCategoriesListName
        .map((c) => c.arabicName)
        .includes(this.allCategoriesName.arabicName)
    ) {
      this.fourthLevelCategoriesList = [
        allFourthLevelCategoriesNode,
        ...this.fourthLevelCategoriesList,
      ];
    }
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
