import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { PreOrderTopBannerPresenter } from './presenter/preorder-top-banner.presenter';
import { PreOrderTopBannerSideEffect } from './presenter/preorder-top-banner.sideEffects';
import { PreOrderTopBannerViewEvents } from './presenter/preorder-top-banner.viewEvents';
import { PreOrderTopBannerViewState } from './presenter/preorder-top-banner.viewState';

@Component({
  selector: 'app-preorder-top-banner',
  standalone: true,
  imports: [TranslateModule, NgFor, NgIf, NgClass],
  templateUrl: './preorder-top-banner.component.html',
  styleUrls: ['./preorder-top-banner.component.scss'],
  providers: [PreOrderTopBannerPresenter],
})
export class PreorderTopBannerComponent extends BaseComponent<
  PreOrderTopBannerPresenter,
  PreOrderTopBannerViewState,
  PreOrderTopBannerViewEvents,
  PreOrderTopBannerSideEffect
> {
  public assetsPath = 'assets/img/pre-order-banner/';

  screenWidth: number;

  @Output() scrollToProducts = new EventEmitter<boolean>();

  @Output() scrollToExternalProducts = new EventEmitter<boolean>();

  public presenter: PreOrderTopBannerPresenter = inject(PreOrderTopBannerPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
    this.screenWidth = window.screen.width;
  }

  onSideEffect(sideEffect: PreOrderTopBannerSideEffect): void {
    switch (sideEffect.type) {
      case 'ScrollToProducts': {
        this.scrollToProducts.emit();
        break;
      }
      case 'ScrollToExternalProducts': {
        this.scrollToExternalProducts.emit();
        break;
      }
    }
  }
}
