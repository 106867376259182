import {
  ALL_PRODUCTS_CATEGORY,
  ALL_SALE_PRODUCTS_CAROUSEL,
  COLLECTION_PRODUCTS_CAROUSEL_NAME,
  DISCOUNTED_PRODUCTS_CAROUSEL_TITLE,
  INTRODUCED_AT_CAROUSEL_NAME,
  LOCKED_PRODUCTS_CAROUSEL_NAME,
  LOCKED_PRODUCTS_CATEGORY,
  LOCKED_TESTABLE_PRODUCTS_CATEGORY,
  SELF_CARE_CATEGORY,
  TOYS_CATEGORY,
} from '.';
import { CarouselInterface } from '../interfaces/product.interafce';
import { SORT_BY_INTRODUCED_AT } from './category-products';

export const TAAGER_RECOMMENDATIONS_GROUP: CarouselInterface = {
  featuredGroupId: 1,
  title: COLLECTION_PRODUCTS_CAROUSEL_NAME,
};

export const FEATURED_CATEGORY_NAME = 'Featured Products';

export const LOCKED_PRODUCTS_CAROUSEL: CarouselInterface = {
  title: LOCKED_PRODUCTS_CATEGORY,
  lockedOnly: true,
  sorting: SORT_BY_INTRODUCED_AT,
};

export const MERCHANT_LOCKED_PRODUCT_CAROUSEL: CarouselInterface = {
  title: LOCKED_PRODUCTS_CAROUSEL_NAME,
  merchantLockedOnly: true,
  sorting: SORT_BY_INTRODUCED_AT,
};

export const SALE_PRODUCTS_CAROUSEL: CarouselInterface = {
  title: ALL_SALE_PRODUCTS_CAROUSEL,
  categoryName: ALL_PRODUCTS_CATEGORY,
  sorting: SORT_BY_INTRODUCED_AT,
};

export const DISCOUNTED_PRODUCTS_CAROUSEL: CarouselInterface = {
  title: DISCOUNTED_PRODUCTS_CAROUSEL_TITLE,
  categoryName: ALL_PRODUCTS_CATEGORY,
  sorting: SORT_BY_INTRODUCED_AT,
};

export const PRODUCTS_LANDING_PAGE_CAROUSELS: { [key: string]: CarouselInterface } = {
  ALL_PRODUCTS_CAROUSEL: {
    title: ALL_PRODUCTS_CATEGORY,
    categoryName: ALL_PRODUCTS_CATEGORY,
    sorting: SORT_BY_INTRODUCED_AT,
    randomize: true,
  },
  INTRODUCED_AT_CAROUSEL: {
    title: INTRODUCED_AT_CAROUSEL_NAME,
    categoryName: ALL_PRODUCTS_CATEGORY,
    sorting: SORT_BY_INTRODUCED_AT,
  },
  LOCKED_TESTABLE_PRODUCTS_CAROUSEL: {
    title: '',
    categoryName: LOCKED_TESTABLE_PRODUCTS_CATEGORY,
    sorting: SORT_BY_INTRODUCED_AT,
    randomize: true,
  },
};

export const SUB_BANNERS_DATA = [
  {
    categoryName: TOYS_CATEGORY,
    bannerSource: 'assets/img/toys_right_banner.jpg',
  },
  {
    categoryName: SELF_CARE_CATEGORY,
    bannerSource: 'assets/img/self_care_left_banner.jpg',
  },
];
